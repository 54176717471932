import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const AppButton = ({ icon, name, title, url }) => (
	<a
		className={cx(styles.AppButton, styles[name])}
		href={url}
		title={title}
		target="_blank"
	>
		{icon}
	</a>
);

AppButton.propTypes = {
	icon: PropTypes.object,
	name: PropTypes.string,
	title: PropTypes.string,
	url: PropTypes.string
};

export default AppButton;
