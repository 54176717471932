import React from 'react';

import Container from 'components/Container';

import AppButton from './components/AppButton';
import icons from './components/icons';

import styles from './styles.module.scss';

const appsData = [
	{
		title: 'Apple: App Store',
		url: 'https://apps.apple.com/ru/app/id1581979387',
		name: 'appStore'
	},
	{
		title: 'Google Play',
		url: 'https://play.google.com/store/apps/details?id=ru.gosuslugi.culture',
		name: 'googlePlay'
	},
	{
		title: 'Huawei: AppGallery',
		url: 'https://appgallery.huawei.com/app/C104662065',
		name: 'appGallery'
	},
	{
		title: 'RuStore',
		url: 'https://apps.rustore.ru/app/ru.gosuslugi.culture ',
		name: 'ruStore'
	}
];

const AppLinks = () => (
	<Container>
		<section className={styles.AppLinks}>
			<div className={styles.AppLinks__Inner}>
				<div className={styles.AppLinks__Content}>
					<h2 className={styles.AppLinks__Title}>Скачать приложение</h2>
					<div className={styles.AppLinks__List}>
						{appsData.map((app) => (
							<AppButton
								key={app.title}
								icon={icons[app.name]}
								name={app.name}
								title={app.title}
								url={app.url}
							/>
						))}
					</div>
				</div>
				<div className={styles.AppLinks__MultiWave}>{icons.multiWave}</div>
				<div className={styles.AppLinks__CircleContrast}>
					{icons.circleContrast}
				</div>
				<div className={styles.AppLinks__ArcContrast}>{icons.arcContrast}</div>
				<div className={styles.AppLinks__Rhombus}>{icons.rhombus}</div>
			</div>
		</section>
	</Container>
);

export default AppLinks;
